import React from "react";
import { Row, Col, Button } from "reactstrap";
import { BsSoundwave } from "react-icons/bs";
import ModalRequest from "../Modals/ModalReqeust";

const HomeMobileHero = ({ toggle, users_location, set_users_location, modal }) => {
  return (
    <Row className="hero-section">
      <Col lg={12} className="hero-col-left">
        <div className="hero-col-left-container">
          <p className="text-semi-muted small click-notification text-sx-small">Tap QR code to request or</p>
          <p className="hero-content-one">Tap to Request</p>
          <div className="arrow-container">
            <div className="arrow_home bounce"></div>
          </div>
          <div className="request-btn-container">
            <div id="loading">
              <div className="outer-shadow"></div>
              <Button id="HeroButton" className="inner-shadow" onClick={toggle}>
                <div className="request-icon-container">
                  <div className="camera-design"></div>
                  <BsSoundwave />
                </div>
              </Button>
              <div className="hold left">
                <div className="fill"></div>
              </div>
              <div className="hold right">
                <div className="fill"></div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <ModalRequest isOpen={modal} toggle={toggle} users_location={users_location} set_users_location={set_users_location} />
    </Row>
  );
};

export default HomeMobileHero;
