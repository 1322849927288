import React from "react";
import { Row, Col, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import HeadLogoImg from "../../static/img/tipit_logo_pro(whitey).png";
import { CgMenuRightAlt } from "react-icons/cg";
import { TiCancel } from "react-icons/ti";
import MainMenu from "../Menu/MainMenu";
import { MdSupportAgent } from "react-icons/md";
import "../css/_modal.scss";

const HomeHeader = ({ trigger_camera, menuModal, toggle_menu, handleFileChange }) => {
  return (
    <header className="main-header">
      <Row className="header-row">
        <Col className="header-left">
          <div className="header-left-div">
            <div className="header-logo-container">
              <div className="logo-img-container">
                <img className="header-logo-img" src={HeadLogoImg} />
              </div>
            </div>
          </div>
        </Col>
        <Col className="header-right">
          <div className="header-right-div">
            <div className="qr-code-container">
              <button className="camera-button" onClick={trigger_camera}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-qr-code-scan" viewBox="0 0 16 16">
                  <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z" />
                  <path d="M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z" />
                  <path d="M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z" />
                  <path d="M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z" />
                  <path d="M12 9h2V8h-2z" />
                </svg>
              </button>
            </div>
            <input type="file" id="cameraInput" accept="image/*" capture="environment" onChange={handleFileChange} />
            <button className="menu-button" onClick={toggle_menu}>
              <CgMenuRightAlt className="home-menu-icon" />
            </button>
            <Modal className="modal-container main-menu-modal-container" isOpen={menuModal} toggle={toggle_menu} modalTransition={{ timeout: 100 }}>
              <ModalBody className="main-menu-body">
                <ModalHeader className="menu-modal-header" toggle={toggle_menu} charcode="close">
                  <h1 style={{ color: "#fff" }}>Tipit Menu Assistance</h1>
                </ModalHeader>
                <MainMenu />
                <div className="menu-button-container">
                  <Button className="exit-menu" onClick={toggle_menu}>
                    Exit&nbsp;
                    <TiCancel />
                  </Button>
                </div>

                <div className="help-wrapper">
                  <a href="mailto:tipit@tipit.ai" className="menu-help-link" rel="noreferrer" target="_blank">
                    Need more assistance ?&nbsp; <MdSupportAgent className="support-icon" />
                  </a>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </Col>
      </Row>
    </header>
  );
};

export default HomeHeader;
