import "./App.css";
import "./static/css/main.css";
import React, { useEffect, useState } from "react";
import Home from "./Components/Homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./_modal.scss";
import { useTrackedState } from "./Components/Store/store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import FooterMenuRouter from "./Components/FooterMenu/FooterMenuRouter";
const ProfileRouter = React.lazy(() => import("./Components/Profile/ProfileRoute"));
const HowItWorks = React.lazy(() => import("./Components/HowItWorks/HowItWorks"));

// import ProfileRouter from "./Components/Profile/ProfileRouter";
const stripePromise = loadStripe("pk_live_3uFtbQZpgtQn8MlOsGIB72Ho00uM2DLggw", {
  stripeAccount: "acct_1EGGzzLQDV6Lrdez",
});

const options = {
  mode: "payment",
  currency: "usd",
  amount: 200,
};

const App = () => {
  const state = useTrackedState();
  const clientSecret = state.client_secret ? state.client_secret : "empty";
  const [is_footer, setIsFooterPage] = useState(false);
  const [is_profile, setIsProfilePage] = useState(false);

  useEffect(() => {
    if (window.location.href.indexOf("view") > -1) {
      setIsFooterPage(true);
    } else {
      setIsFooterPage(false);
    }

    if (window.location.href.indexOf("profile") > -1) {
      setIsProfilePage(true);
    } else {
      setIsProfilePage(false);
    }
  }, []);

  return (
    <div className="App">
      <Elements stripe={stripePromise} options={options}>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={is_footer ? <FooterMenuRouter /> : <Home />} />
            <Route path="/profile/*" element={<ProfileRouter />} />
          </Routes>
        </BrowserRouter>
      </Elements>
    </div>
  );
};

export default App;
