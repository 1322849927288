export default function CheckPhone(event, err) {
  const errorMsg = err;
  const phone = event ? event.target.value.replace(/\D/g, "") : "";

  if (phone.length < 10) {
    errorMsg.style.display = "block";
    errorMsg.innerHTML = "Phone Number Must Be 10 digits";
  }
  if (phone.length === 10) {
    errorMsg.style.display = "none";
  }

  return phone;
}
