// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hiw-row p {
  font-size: 0.985rem;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
}

.hiw-row {
  top: -20px;
  position: relative;
}

.hiw-row h3 {
  background: #212747;
  padding: 8px 15px;
  border-radius: 10px;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.hiw-row a {
  color: #92c9ff;
  text-decoration: none;
  font-weight: bold;
}

.hiw-row,
.hiw-row h1,
.hiw-row h2 {
  font-family: "Raleway", sans-serif;
}

.check-arrow-hiw {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/HowItWorks/hiw.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;;;EAGE,kCAAkC;AACpC;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".hiw-row p {\n  font-size: 0.985rem;\n  font-weight: 400;\n  display: flex;\n  align-items: flex-start;\n}\n\n.hiw-row {\n  top: -20px;\n  position: relative;\n}\n\n.hiw-row h3 {\n  background: #212747;\n  padding: 8px 15px;\n  border-radius: 10px;\n  margin-bottom: 1rem;\n  margin-top: 0.5rem;\n}\n\n.hiw-row a {\n  color: #92c9ff;\n  text-decoration: none;\n  font-weight: bold;\n}\n\n.hiw-row,\n.hiw-row h1,\n.hiw-row h2 {\n  font-family: \"Raleway\", sans-serif;\n}\n\n.check-arrow-hiw {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
