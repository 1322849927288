// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27><path stroke=%27white%27 fill=%27white%27 d=%27M6 8l-1 1l5 5l5-5l-1-1l-4 4l-4-4z%27/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion{--bs-accordion-bg: transparent !important;--bs-accordion-color: #fff !important;--bs-accordion-btn-color: #fff !important;--bs-accordion-btn-icon: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;color:#fff}.accordion-button:not(.collapsed){background-color:rgba(0,0,0,0) !important}.accordion-button{color:#fff !important}.accordion-button::after{color:#fff !important}.accordion-button::before{color:#fff !important}.accordion-header{color:#fff !important}`, "",{"version":3,"sources":["webpack://./src/Components/Menu/_accordion.scss"],"names":[],"mappings":"AAAA,WACE,yCAAA,CACA,qCAAA,CACA,yCAAA,CACA,2EAAA,CACA,UAAA,CAGF,kCACE,yCAAA,CAGF,kBACE,qBAAA,CAGF,yBACE,qBAAA,CAGF,0BACE,qBAAA,CAGF,kBACE,qBAAA","sourcesContent":[".accordion {\n  --bs-accordion-bg: transparent !important;\n  --bs-accordion-color: #fff !important;\n  --bs-accordion-btn-color: #fff !important;\n  --bs-accordion-btn-icon: url(\"data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path stroke='white' fill='white' d='M6 8l-1 1l5 5l5-5l-1-1l-4 4l-4-4z'/></svg>\") !important;\n  color: #fff;\n}\n\n.accordion-button:not(.collapsed) {\n  background-color: transparent !important;\n}\n\n.accordion-button {\n  color: #fff !important;\n}\n\n.accordion-button::after {\n  color: #fff !important;\n}\n\n.accordion-button::before {\n  color: #fff !important;\n}\n\n.accordion-header {\n  color: #fff !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
