import React from "react";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { IoMdArrowRoundForward } from "react-icons/io";
import "./hiw.css";
import { RiExternalLinkLine } from "react-icons/ri";

const HowItWorks = () => {
  return (
    <div>
      <Row className="hiw-row" style={{ color: "#fff", padding: "0px 10px", margin: "0 auto 50px" }}>
        <Col sm={12}>
          <h1>
            <b>How it works</b>
          </h1>
        </Col>
        <Col sm={12}>
          <h3>Send a Song Request</h3>
          <p>
            <span>
              {" "}
              <IoMdArrowRoundForward className="checkout-arrow check-arrow-hiw" />
              &nbsp;
            </span>
            Click the Circle button or scan the DJ's QR code to send a song request or shoutout.
          </p>
          <p>
            <span>
              {" "}
              <IoMdArrowRoundForward className="checkout-arrow check-arrow-hiw" />
              &nbsp;
            </span>
            A tip is required to send a request and is based on the DJ's pricing.
          </p>
          <p>
            <span>
              {" "}
              <IoMdArrowRoundForward className="checkout-arrow check-arrow-hiw" />
              &nbsp;
            </span>
            The DJ will receive your song request and has 15 minutes to accept or deny your request.
          </p>
          <p>
            <span>
              {" "}
              <IoMdArrowRoundForward className="checkout-arrow check-arrow-hiw" />
              &nbsp;
            </span>
            You will be refunded immediately if your request is not accepted or played.
          </p>
          <p>
            <b>It's that simple.</b>
          </p>
        </Col>
        <Col sm={12}>
          <h3>Become a Tipit DJ</h3>
          <p>If you'd like to receive song request as a DJ follow these simple steps:</p>
          <p>
            <b>Sign up &nbsp;</b>
            <a href="http://music.tipit.ai/signup" target="_blank" rel="noreferrer">
              Become a vendor&nbsp;
              <RiExternalLinkLine />
            </a>
          </p>
          <p>
            <b>Login </b> to your dashboard&nbsp;{" "}
            <a href="http://music.tipit.ai" target="_blank" rel="noreferrer">
              Vendor Login&nbsp;
              <RiExternalLinkLine />
            </a>
          </p>
          <p>Click "Go Live" and you're ready to receive request.</p>
        </Col>
      </Row>
    </div>
  );
};

export default HowItWorks;
