import React, { useState } from "react";
import { Accordion, AccordionItem, AccordionBody, AccordionHeader } from "reactstrap";
import { FaCheck } from "react-icons/fa";
import { FiCircle } from "react-icons/fi";
import "./style.css";
import "./_accordion.scss";
const MainMenu = () => {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <div>
      <div className="main-menu-container">
        <div className="accordion-wrapper">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="1">
                <span className="font-weight-bold">How it works ?</span>
              </AccordionHeader>
              <AccordionBody accordionId="1">
                <ul className="menu-ul">
                  <li>
                    <b>Step 1:</b> Click the BIG Circle Button or tap the QR icon a the top of page to Scan.
                  </li>
                  <li>
                    <b>Step 2:</b> Choose a person from the list (if you're not using QR code scan). If nothing appears this simply means no one is online to accept request.
                  </li>
                  <li>
                    <b>Step 3:</b> Click "send request" button.
                  </li>
                  <li>
                    <b>Step 4:</b> Type in a song (e.g. Drake / God's Plan) or a shoutout (e.g. Happy Birthday Joe) and click next. We also have voice record available.
                  </li>
                  <li>
                    <b>Step 5:</b> Select a tip amount (or leave it as default) and pay with Apple Pay, Google pay or Wallet. *Crypto coming soon.
                  </li>
                </ul>
                <br></br>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="2">
                <span className="font-weight-bold">Request didn't play ?</span>
              </AccordionHeader>
              <AccordionBody accordionId="2">
                <ul className="help-checklist menu-list-items">
                  <li>
                    <FiCircle className="menu-circle" />
                    &nbsp;If your request was not fulfilled please check with the person or organization you've sent the tip to.
                  </li>
                  <li>
                    <FiCircle className="menu-circle" />
                    &nbsp;If your request was denied you will receive a text message update and you'll be refunded within 48 hours.
                  </li>
                  <li>
                    <FiCircle className="menu-circle" />
                    &nbsp;If your request was accepted you'll receive a text message before your request plays. Be sure to stick around, we do not offer refunds for your non-presence.
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="3">
                <span className="font-weight-bold">Ways to pay</span>
              </AccordionHeader>
              <AccordionBody accordionId="3">
                <ul className="menu-payments-list menu-list-items">
                  <p>We accept these types of payments</p>
                  <li>
                    <FaCheck className="fa-checks" />
                    &nbsp;Credit / Debit Card
                  </li>
                  <li>
                    <FaCheck className="fa-checks" />
                    &nbsp;Apple Pay / Google Pay
                  </li>
                  <li>
                    <FaCheck className="fa-checks" />
                    &nbsp;Crypto
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="4">
                <span className="font-weight-bold">Tips & Tricks</span>
              </AccordionHeader>
              <AccordionBody accordionId="4">
                <p>
                  <FiCircle className="menu-circle" />
                  &nbsp; Note that when you send a request to an entertainer they receive that request to their personal dashboard. They can freely accept or deny your request (in this case you'll
                  receive a refund)
                </p>
                <p>
                  <FiCircle className="menu-circle" />
                  &nbsp; Download our app for optimal service
                </p>
                <p>
                  <FiCircle className="menu-circle" />
                  &nbsp; Don't be afraid to ask the entertainer(s) you've tipped about your request.
                </p>
                <p>** There will be a direct chat system between you and the entertainer soon **</p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="5">
                <span className="font-weight-bold">Become a Merchant</span>
              </AccordionHeader>
              <AccordionBody accordionId="5">
                When you Register to become a merchant there is a quick verification process. Once you're approved you'll be emailed tutorials and instructions on earning money and receivng request
                using Tipit's dashboard.
                <a href="https://www.tipit.ai/?register=true" target="_blank" rel="noreferrer">
                  {" "}
                  Click Here{" "}
                </a>
                to get started.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
        {/**accordion-wrapper */}
      </div>
    </div>
  );
};

export default MainMenu;
