import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import Geocode from "react-geocode";
// import { GeoLocationContext } from "../Api/Context/GeoLocationContext";
import axios from "axios";
import CheckEmail from "../Components/FormValidation/CheckEmail";
import { useSearchParams } from "react-router-dom";
import ModalSignUp from "../Components/Modals/ModalSignUp";
import RegisterLoginRoute from "./Auth/Form";
import { Provider } from "../Components/Store/VendorStore";
import HowItWorks from "./HowItWorks/HowItWorks";
import jsQR from "jsqr-es6";
import Header from "./Header/HomeHeader";
import Footer from "../Components/Footer/Footer";
import "./css/_modal.scss";
import HomeMobileHero from "./HeroSections/HomeMobileHero";

const HomePage = () => {
  const [modal, setModal] = useState(false);
  const [applyDisabled, setApplyDisabled] = useState(true);
  const [menuModal, setMenuModal] = useState(false);
  const [users_location, set_users_location] = useState("");
  // const [disabled, setDisabled] = useState(false);
  const [step_nums, set_step_nums] = useState("1");
  const [show, setShow] = useState(false);
  const [loginShow, setLoginShow] = useState(false);
  const [useremail, set_useremail] = useState({ qr_code_email: "" });
  const [searchParam, setSearchParam] = useSearchParams();
  // const [qrSearch, setQrSearch] = useState();
  let open_modal = searchParam ? searchParam.get("register") : "";
  let qr_search = searchParam ? searchParam.get("iamdj") : "";
  let query_request = searchParam ? searchParam.get("send-song-request") : "";
  let modal_auth = searchParam ? searchParam.get("modal") === "true" : false;

  useEffect(() => {
    if (open_modal === true || open_modal === "true") {
      setShow(true);
    } else {
      setShow(false);
    }
    if (modal_auth === "true" || modal_auth === true) {
      setModal(true);
    }
    if (qr_search) {
      setModal(true);
    }
    if (query_request) {
      setModal(true);
    }
  }, [open_modal, modal_auth]);

  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.overflow = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [modal]);

  useEffect(() => {
    if (window.location.pathname === "/success") {
      document.getElementsByClassName("steps-title").style.display = "none";
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/success") {
      document.getElementsByClassName("steps-title").style.display = "none";
    }
  }, []);

  useEffect(() => {
    if (window.location.href.indexOf("request") > -1) {
      setModal(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname == "/") {
      set_step_nums("1");
    } else if (window.location.href.indexOf("request") > -1) {
      set_step_nums("2");
    } else if (window.location.href.indexOf("checkout") > -1) {
      set_step_nums("3");
    }
  }, []);

  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [modal]);

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
    document.body.style.overflowY = "hidden";
  };
  const handleClose = () => {
    setShow(false);
    setSearchParam(false);
    document.body.style.overflowY = "scroll";
  };

  const handleLoginShow = (e) => {
    e.preventDefault();
    setLoginShow(true);
    document.body.style.overflowY = "hidden";
  };
  const handleLoginClose = () => {
    setLoginShow(false);
    setSearchParam(false);
    document.body.style.overflowY = "scroll";
  };

  const toggle = () => {
    setModal(!modal);
    if (window.location.href.indexOf("success") > -1) {
      window.location.href = "/";
    }
    if (window.location.href.indexOf("request") > -1) {
      window.location.href = "/";
    }
  };
  const toggle_menu = () => setMenuModal(!menuModal);
  // const handleCloseModal = () => setModal(false);

  Geocode.setApiKey("AIzaSyAk6KX8QPv6AYHbz3IexgbeiIZvFjelDpQ");
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();

  // useEffect(() => {
  //   getCurrentLoaction();
  // }, []);

  // const successPosition = (position) => {
  //   const latitude = position.coords.latitude;
  //   const longitude = position.coords.longitude;
  //   Geocode.fromLatLng(latitude, longitude).then(
  //     (response) => {
  //       const address = response.results[0].formatted_address;
  //       let city, country, geostate;

  //       for (let i = 0; i < response.results[0].address_components.length; i++) {
  //         for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
  //           switch (response.results[0].address_components[i].types[j]) {
  //             case "locality":
  //               city = response.results[0].address_components[i].short_name;
  //               break;
  //             case "administrative_area_level_1":
  //               geostate = response.results[0].address_components[i].short_name;
  //               break;
  //             case "country":
  //               country = response.results[0].address_components[i].short_name;
  //               break;
  //           }
  //         }
  //       }

  //       const geolocation = city + ", " + geostate;
  //       if (geolocation) {
  //         set_users_location(geolocation);
  //         localStorage.setItem("city", city);
  //         localStorage.setItem("state", geostate);
  //         localStorage.setItem("user_location", city + ", " + geostate);
  //       }
  //       console.log(city + ", " + geostate, country);
  //       console.log(address);
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // };

  // const getCurrentLoaction = () => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition((pos) => successPosition(pos));
  //   } else {
  //     alert("Your Browser doesn't support location service !");
  //   }
  // };

  const get_qr_code = (e) => {
    const value = e.target.value;
    const error = document.getElementById("qr-email-error");
    const qrcode = CheckEmail(value, error);
    if (qrcode) {
      set_useremail((prev) => {
        return { ...prev, [e.target.name]: qrcode };
      });
      setApplyDisabled(false);
    }
  };

  const qr_apply_now = async (e) => {
    e.preventDefault();
    const error = document.getElementById("qr-email-error");
    const success = document.getElementById("success-qr-apply");
    const data = {
      email: useremail.qr_code_email,
    };

    await axios
      .post(process.env.REACT_APP_API_URL + "/api/qr-code-apply", { data })
      .then((res) => {
        if (res.status === 200) {
          document.getElementById("ApplyRemove").style.display = "none";
          console.log("QR CODE SUCCESS");
          success.style.display = "block";
          success.innerHTML = "Success! Please Check Your Email.";
          localStorage.setItem("qr_applied", true);
        }
      })
      .catch((err) => {
        console.log("Qr Code Data:" + JSON.stringify(err));
        if (err.response.status === 401) {
          error.style.display = "block";
          error.innerHTML = "Email has already applied";
          setTimeout(() => {
            error.style.display = "none";
          }, 10000);
        }
      });
  };

  const footer_links = (e) => {
    const paths = e.target.name;
    window.location.href = process.env.REACT_APP_DOMAIN + `/view/${paths}`;
  };

  const trigger_camera = () => {
    document.getElementById("cameraInput").click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        const image = new Image();
        image.src = imageDataUrl;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0, image.width, image.height);
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            console.log("QR Code detected:", code.data);
          } else {
            console.log("No QR Code detected.");
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div id="HomePageWrapper">
        <div className="hero-container">
          <Header trigger_camera={trigger_camera} menuModal={menuModal} toggle_menu={toggle_menu} handleFileChange={handleFileChange} />
          <div className="desktop">
            <div className="desktop-container">
              <p>Only available on mobile and tablet</p>
            </div>
          </div>
          <div className="hero-section-container mobile">
            <div className="beat-equalizer"></div>
            <div className="hero-container-inner">
              <HomeMobileHero modal={modal} toggle={toggle} users_location={users_location} set_users_location={set_users_location} />
            </div>
          </div>
          <div className="home-button-container">
            <p className="sign-up-link">
              Access your account ?{" "}
              <b>
                <a onClick={handleShow}>Sign in</a>
              </b>
            </p>
            {show && (
              <ModalSignUp className="create-account-form-wrapper" toggle={show} handleClose={handleClose}>
                <Provider>
                  <RegisterLoginRoute />
                </Provider>
              </ModalSignUp>
            )}
            <Button onClick={handleLoginShow} id="HeroButton" className="hero-request-btn">
              <p className="request-p-btn">How it works</p>
            </Button>

            {loginShow && (
              <ModalSignUp toggle={loginShow} handleClose={handleLoginClose}>
                <Provider>
                  <HowItWorks />
                </Provider>
              </ModalSignUp>
            )}
          </div>
        </div>
        {/** hero-container */}
        <div className="three-lines"></div>
        <Footer get_qr_code={get_qr_code} qr_apply_now={qr_apply_now} applyDisabled={applyDisabled} footer_links={footer_links} />
        <div className="footer-bottom">
          <div className="footer-bottom-container">
            <p className="copy-right">&copy; 2024 Tipit, LLC. Visual Embassy, All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;
