import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { Provider } from "../Components/Store/store";

const TipitVendors = React.lazy(() => import("../Components/ListVendors/ListVendors"));
const TipitRequest = React.lazy(() => import("../Components/SongRequest/SongRequest"));
const TipitCheckout = React.lazy(() => import("../Components/Checkout/Checkout"));
const TipitShoutout = React.lazy(() => import("../Components/Shoutout/Shoutout"));
const TipitFormComplete = React.lazy(() => import("../Components/Register/FormComplete"));
const Success = React.lazy(() => import("../Components/Checkout/Success"));
const SkipPage = React.lazy(() => import("../Components/SkipNotice"));
const BookMe = React.lazy(() => import("../Components/BookMe/BkComponent"));
const WalletPaySuccess = React.lazy(() => import("../Components/Checkout/ApplePaySuccess"));

const Main = () => {
  const location = useLocation();
  useEffect(() => {
    document.body.style.overflowY = "hidden";
  }, []);
  return (
    <>
      <Provider>
        <React.Suspense
          fallback={
            <div>
              <ScaleLoader className="scale-loader-modal" />
              <p className="scale-loading-txt">Loading ...</p>
            </div>
          }
        >
          <Routes location={location}>
            <Route exact path="/" element={<TipitRequest />} />
            <Route path="/request/:id" element={<TipitVendors />} />
            <Route path="/bookme/:id" element={<BookMe />} />
            <Route path="/shoutout/:id" element={<TipitShoutout />} />
            <Route path="/checkout/:id" element={<TipitCheckout />} />
            <Route path="/skip" element={<SkipPage />} />
            <Route path="/success" element={<Success />} />
            <Route path="/apple-pay-success" element={<WalletPaySuccess />} />
            <Route path="/reg-success" element={<TipitFormComplete />} />
          </Routes>
        </React.Suspense>
      </Provider>
    </>
  );
};

export default Main;
