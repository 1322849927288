import { useState } from "react";
import { createContainer } from "react-tracked";

/**This File uses React Tracked To Pass Variable data to Components  */

const initialState = {
  full_name: "",
  phone_number: "",
  vendor_email: "",
  ambassador: "",
  qr_code: "",
};

const useMyState = () => useState(initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useVendorState,
} = createContainer(useMyState);
