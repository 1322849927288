import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./fpm.css";

const AboutPage = React.lazy(() => import("./About/AboutPage"));
const Policypage = React.lazy(() => import("./Policy/PolicyPage"));
const Terms = React.lazy(() => import("./Terms/Terms"));

const vendorModal = document.getElementById("VendorModal");

const FooterMenuRouter = () => {
  useEffect(() => {
    if (vendorModal) {
      vendorModal.style.display = "none";
    }
  });
  return (
    <div id="FMRouter">
      <div className="fmr-container">
        <Routes>
          <Route exact path="/view/about" element={<AboutPage />} />
          <Route exact path="/view/policy" element={<Policypage />} />
          <Route exact path="/view/terms-of-service" element={<Terms />} />
        </Routes>
      </div>
    </div>
  );
};

export default FooterMenuRouter;
