import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import AppMain from "./AppMain";
import reportWebVitals from "./reportWebVitals";
import Complete from "./Components/Complete/Complete";

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/serviceWorker.js")
//       .then((registration) => {
//         console.log("Service Worker registered with scope:", registration.scope);
//       })
//       .catch((error) => {
//         console.log("Service Worker registration failed:", error);
//       });
//   });
// }

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

if (window.location.pathname === "/user/complete" || window.location.href.indexOf("complete") > -1) {
  root.render(<Complete />);
} else {
  root.render(<AppMain />);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
