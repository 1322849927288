export default function CheckEmail(value, err) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const errorMsg = err;
  if (!value.match(mailformat) || value.trim().length < 6) {
    errorMsg.style.display = "block";
    errorMsg.innerHTML = "Please enter a valid email";
  } else {
    errorMsg.style.display = "none";
  }

  return value;
}
