import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { Row, Col } from "reactstrap";
import { TiCancel } from "react-icons/ti";
import Main from "../../Containers/Main";
import { GeoLocationContext } from "../../Api/Context/GeoLocationContext";
import HeadLogoImg from "../../static/img/tipit_logo_pro(whitey).png";
import PropTypes from "prop-types";

const ModalRequest = ({ isOpen, toggle, users_location, set_users_location }) => {
  return (
    <Modal className="modal-container" charcode="close" isOpen={isOpen} toggle={toggle} modalTransition={{ timeout: 100 }}>
      <div className="beat-equalizer-two"></div>
      <ModalBody>
        <div className="vendor-list-container">
          <div className="modal_header">
            <Row>
              <Col sm={12}>
                <div className="modal-header-img-container">
                  <a href="/" className="logo-href">
                    <img src={HeadLogoImg} alt="" className="modal-logo" />
                  </a>
                </div>
              </Col>
              <Col sm={6}>
                <p className="steps-title">
                  Step&nbsp;<span id="step-no"></span> of 3
                </p>
              </Col>
            </Row>
          </div>
          <Row className="vendor_row">
            {/* <div id="VendorListBg" className="black-bg-vendor-list"></div> */}
            <div className="modal-close-btn">
              <Button className="exit-modal" onClick={toggle}>
                Exit&nbsp;
                <TiCancel className="cancel-modal-icon" />
              </Button>
            </div>
            <Col lg={12}>
              <GeoLocationContext.Provider value={[users_location, set_users_location]}>
                <Main isOpen={isOpen} handleCloseModal={toggle} />
              </GeoLocationContext.Provider>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
};

ModalRequest.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.bool,
  users_location: PropTypes.string,
  set_users_location: PropTypes.string,
};

export default ModalRequest;
