export default function TextInputFields(value, err) {
  const errorMsg = err;
  if (value.length < 2) {
    errorMsg.style.display = "block";
    errorMsg.innerHTML = "Make sure all fields are valid";
  } else {
    errorMsg.style.display = "none";
  }
  return value;
}
