// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fmr-container {
  width: 90%;
  margin: 0 auto;
  max-width: 991px;
  padding: 50px 0px;
}

#FMRouter {
}
`, "",{"version":3,"sources":["webpack://./src/Components/FooterMenu/fpm.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;AACA","sourcesContent":[".fmr-container {\n  width: 90%;\n  margin: 0 auto;\n  max-width: 991px;\n  padding: 50px 0px;\n}\n\n#FMRouter {\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
