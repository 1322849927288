import React, { useState, useEffect, useCallback } from "react";
import TipitLogo from "../../static/img/tipit_logo_pro(whitey).png";
import { FaSignature } from "react-icons/fa";
import CheckPhone from "../FormValidation/CheckPhone";
import { useSearchParams } from "react-router-dom";
import { BiLogInCircle } from "react-icons/bi";
import axios from "axios";
import RegisterRoute from "../Register/Vendors";
import "../../Components/Modals/styles.css";
import ModalSignUp from "../Modals/ModalSignUp";
import { Provider } from "../Store/VendorStore";

const CustomerLogin = () => {
  const [disabled, setDisabled] = useState(true);
  const [finished, set_finished] = useState(false);
  const [show, setShow] = useState(false);

  const [phone_number, set_phone_number] = useState(""); // Change to hold just the phone number string

  const [searchParams] = useSearchParams();
  const ab = searchParams ? searchParams.get("ab") : "";

  const check_fields = useCallback(() => {
    if (phone_number.length > 9) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [phone_number]);

  useEffect(() => {
    check_fields();
  }, [check_fields]);

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
    document.body.style.overflowY = "hidden";
  };

  const register_button = async (e) => {
    e.preventDefault();
    const error_message = document.getElementById("UserError");
    const comment_bubble = document.getElementById("CommentBubble");
    comment_bubble.style.display = "block";
    error_message.style.display = "block";
    error_message.textContent = "Tipit is a members only platform. Your account is awaiting approval.";

    const values = {
      phone: phone_number, // Correctly set phone number
    };
    // await axios
    //   .post(process.env.REACT_APP_API_URL + "/api/vendor-login", { values })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       set_finished(true);
    //       console.log("Vendor Registration Success");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Vendor Registration Error" + JSON.stringify(err));
    //     if (err.response.status === 401) {
    //       error_message.style.display = "block";
    //       error_message.innerHTML = "Phone Number Already Exists!";
    //       setTimeout(() => {
    //         error_message.style.display = "none";
    //       }, 5000);
    //     }
    //   });
  };

  const check_phone = (e) => {
    const error = document.getElementById("errorPhone");
    const phone = CheckPhone(e, error);

    set_phone_number(phone); // Directly update the phone number string
  };

  return (
    <div id="SignupForm">
      {!show && (
        <div className="vendor-modal-container">
          <div>
            <div className="signup-modal-wrapper">
              {/* Comment Buuble */}
              <div id="CommentBubble" className="comment-bubble" style={{ display: "none" }}>
                <p id="UserError"></p>
              </div>

              <div className="vendor-modal-input-container">
                <h1 className="login-modal-title">Sign In</h1>
                <label className="vendor-modal-label" htmlFor="phone_number">
                  <span className="req-asterisk">*</span>Mobile Number
                </label>
                <br />
                <input
                  onChange={check_phone}
                  value={phone_number}
                  type="tel"
                  inputMode="numeric"
                  name="phone_number"
                  className="vendor-input"
                  maxLength="10"
                  placeholder="Enter Mobile Number"
                  required
                />
                <p id="errorPhone" className="error-msg"></p>
              </div>
              <div className="login-button-wrapper">
                <button disabled={disabled} onClick={register_button} className="register-now login-now">
                  Login&nbsp;
                  <BiLogInCircle />
                </button>
                <br />
              </div>
              <p className="sign-up-link">
                Don't have an account ?{" "}
                <b>
                  <a onClick={handleShow}>Sign up</a>
                </b>
              </p>
            </div>
          </div>
        </div>
      )}
      {show && <RegisterRoute />}
    </div>
  );
};

export default CustomerLogin;
