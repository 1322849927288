import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

const TipitCompleteReg = React.lazy(() => import("../Complete/CompleteRegistration"));
const TipitCongrats = React.lazy(() => import("../Complete/Thankyou"));
const vendorModal = document.getElementById("VendorModal");

const Complete = () => {
  useEffect(() => {
    if (vendorModal) {
      vendorModal.style.display = "none";
    }
  }, [vendorModal]);
  return (
    <BrowserRouter>
      <div id="Complete">
        <div className="complete-container">
          <Routes>
            <Route path="/user/complete" element={<TipitCompleteReg />} />
            <Route path="/user/congrats" element={<TipitCongrats />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Complete;
