import React, { useEffect } from "react";
import { Checkmark } from "react-checkmark";
import { TiCancel } from "react-icons/ti";
import PropTypes from "prop-types";

const FormComplete = ({ onFormSuccess }) => {
  const pageRefresh = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    handleSuccess();
  }, []);

  const handleSuccess = () => {
    onFormSuccess();
  };

  return (
    <div id="waitSuccess">
      <div className="successWrap">
        <br />
        <h1 className="successTitle">
          Success! <Checkmark color="#7854E2" size="96px" />
        </h1>
        <div id="successMsg">
          <h5 className="emailNotif">You're Almost Finished, Check your email to complete registration.</h5>
          <br />
          <button onClick={pageRefresh} className="exit-register">
            Exit&nbsp;
            <TiCancel />
          </button>
        </div>
      </div>
    </div>
  );
};

FormComplete.propTypes = {
  onSuccess: PropTypes.func,
};

export default FormComplete;
