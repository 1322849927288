import React from "react";
import { ImQrcode } from "react-icons/im";
import { Row, Col } from "reactstrap";
import HeadLogoImg from "../../static/img/tipit_logo_pro(whitey).png";
import { AiFillInstagram, AiOutlineTwitter, AiFillFacebook } from "react-icons/ai";
import { IoMdArrowRoundForward } from "react-icons/io";
import { FaDiscord } from "react-icons/fa";
import AppStoreBadge from "../../static/img/app-store-badge.png";
import GoogleBadge from "../../static/img/google-badge.png";
import QrCode from "../../static/img/qr-code.png";

const Footer = ({ get_qr_code, qr_apply_now, applyDisabled, footer_links }) => {
  return (
    <footer className="main-footer">
      <div className="footer-container">
        <Row className="footer-row">
          <Col lg={12} className="footer-left-col">
            <div className="footer-left-container">
              <div className="logo-img-container-footer">
                <div className="footer-left-img">
                  <img src={HeadLogoImg} className="footer-logo" />
                  <span>
                    <img className="footer-qr-code" src={QrCode} alt="qr-code" />
                  </span>
                </div>
                <div className="footer-left-text">
                  <p className="footer-left-p">
                    Get your own personal <br />
                    QR code!
                  </p>
                  <p id="ApplyRemove" className="footer-apply-text">
                    <IoMdArrowRoundForward className="checkout-arrow" />
                    &nbsp;Apply below with your <b>email:</b>
                  </p>
                  <p id="success-qr-apply"></p>
                  <p id="qr-email-error"></p>
                  <div className="footer-input-container">
                    <input onChange={get_qr_code} id="QrCode" name="qr_code_email" type="text" className="qr-code-input" placeholder="youremail@example.com" />
                    <span>
                      <button onClick={qr_apply_now} disabled={applyDisabled} className="qr-apply">
                        Apply Now&nbsp;&nbsp;
                        <ImQrcode />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={12} className="footer-right-col">
            <div className="footer-list-wrapper">
              <div className="disclaimer-footer">
                <h3 className="follow-us-footer">Follow Us</h3>
                <ul className="footer-social-icons">
                  <li>
                    <a className="footer-icons-a" target="_blank" rel="noreferrer" href="https://www.instagram.com/tipit.ai/">
                      <AiFillInstagram className="footer-social-icon" />
                    </a>
                  </li>
                  <li>
                    <a className="footer-icons-a" target="_blank" rel="noreferrer" href="https://twitter.com/usetipit">
                      <AiOutlineTwitter className="footer-social-icon" />
                    </a>
                  </li>
                  <li>
                    <a className="footer-icons-a" target="_blank" href="https://www.facebook.com/usetipit/">
                      <AiFillFacebook className="footer-social-icon" />
                    </a>
                  </li>
                  <li>
                    <FaDiscord className="footer-social-icon" />
                  </li>
                </ul>
                <p className="footer-disclaimer">
                  Tipit is a platform that allows request to happen between performers and audiences. We use a poplular and secure third party gateway Stripe as our debit and credit transactions
                  system. <b>We never store your card details!</b>
                </p>

                <div className="app-store-container">
                  <p className="footer-coming-soon">Try our mobile app</p>
                  <div className="footer-app-store-container">
                    <ul className="app-store-ul">
                      <li>
                        <img src={GoogleBadge} className="google-store-icon footer-app-icons" alt="google-store-badge" />
                      </li>
                      <li>
                        <img src={AppStoreBadge} className="app-store-icon footer-app-icons" alt="app-store-badge" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <ul className="footer-list-ul">
                <li>
                  <button onClick={footer_links} className="footer-link-button" name="about">
                    About
                  </button>
                </li>
                <li>
                  <button onClick={() => (window.location.href = "mailto:tipit@tipit.ai?subject=Request Refund")} className="footer-link-button" name="refund">
                    Refund
                  </button>
                </li>
                <li>
                  <button onClick={() => (window.location.href = process.env.REACT_APP_DOMAIN + "/?register=true")} className="footer-link-button" name="vendors">
                    Join Us
                  </button>
                </li>
                <li>
                  <button onClick={footer_links} className="footer-link-button" name="policy">
                    Policy
                  </button>
                </li>
                <li>
                  <button onClick={() => (window.location.href = "mailto:tipit@tipit.ai")} target="_blank" rel="noreferrer" className="footer-link-button" name="contactus">
                    Contact Us
                  </button>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
