import { useState, useEffect } from "react";
import { createContainer } from "react-tracked";

/**This File uses React Tracked To Pass Variable data to Components  */

const initialState = {
  first_name: "",
  full_name: "",
  song_request: "",
  shoutout_request: "",
  is_shoutout: false,
  is_song_request: typeof Boolean,
  merchant_id: "",
  total_amount: "",
  stripe_amount: "",
  phone_number: "",
  customer_name: "",
  customer_phone: "",
  song_tip_amount: "",
  client_secret: "",
  vendor_username: "",
  vendor_name: "",
  is_member: "",
  payment_id: "",
  spotify_token: "",
  cashapp_status: "",
  cashapp_name: "",
  is_free: "",
  payment_success: false,
  autorequest: window.localStorage.getItem("song_request") || "",
};

const useMyState = () => {
  const [state, setState] = useState(() => {
    // Initialize state from local storage if available
    const storedState = window.localStorage.getItem("appState");
    return storedState ? JSON.parse(storedState) : initialState;
  });

  useEffect(() => {
    // Save state to local storage whenever it changes
    window.localStorage.setItem("appState", JSON.stringify(state));
  }, [state]);

  return [state, setState];
};

export const { Provider, useTrackedState, useUpdate: useSetState } = createContainer(useMyState);
