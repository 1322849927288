import React, { useState, useEffect, useCallback } from "react";
import { FaSignature } from "react-icons/fa";
import TextInputFields from "../FormValidation/TextInputFields";
import CheckEmail from "../FormValidation/CheckEmail";
import CheckPhone from "../FormValidation/CheckPhone";
import { useSearchParams } from "react-router-dom";
import Confetti from "react-confetti";
import FormComplete from "./FormComplete";
import axios from "axios";
import "./style.css";
import { useVendorState, useTrackedState } from "../Store/VendorStore";

const Vendors = () => {
  const state = useTrackedState();
  const setVendor = useVendorState();
  const [showConfetti, setShowConfetti] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [finished, set_finished] = useState(false);

  const [searchParams] = useSearchParams();
  const ab = searchParams ? searchParams.get("ab") : "";

  const handleFormSuccess = () => {
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 10000); // Optional: Set animation duration
  };

  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }
  function generateId(len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }

  const check_fields = useCallback(() => {
    var mailformat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
    const cemail = state.vendor_email;
    const email = cemail.match(mailformat);
    if (state.full_name.length > 2 && state.phone_number.length > 9 && email) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [state.vendor_email, state.full_name, state.phone_number]);

  useEffect(() => {
    check_fields();
  }, [check_fields]);

  const register_button = async (e) => {
    e.preventDefault();
    const error_message = document.getElementById("UserError");

    const values = {
      full_name: state.full_name,
      phone: state.phone_number,
      email: state.vendor_email,
      signup_code: generateId(20),
      ambassador: ab ? ab : "random",
    };

    await axios
      .post(process.env.REACT_APP_API_URL + "/api/vendor-register", { values })
      .then((res) => {
        if (res.status === 200) {
          set_finished(true);
          console.log("Vendor Registration Success");
        }
      })
      .catch((err) => {
        console.log("Vendor Registration Error" + JSON.stringify(err));
        if (err.response.status === 401) {
          error_message.style.display = "block";
          error_message.innerHTML = "Phone Number Already Exists!";
          setTimeout(() => {
            error_message.style.display = "none";
          }, 5000);
        }
      });
  };

  const check_name = (e) => {
    const value = e.target.value;
    const error = document.getElementById("errorName");
    const name = TextInputFields(value, error);

    setVendor((prev) => {
      return { ...prev, [e.target.name]: name };
    });
  };

  const check_email = (e) => {
    const value = e.target.value;
    const error = document.getElementById("errorEmail");
    const email = CheckEmail(value, error);

    setVendor((prev) => {
      return { ...prev, [e.target.name]: email };
    });
  };

  const check_phone = (e) => {
    const error = document.getElementById("errorPhone");
    const phone = CheckPhone(e, error);

    setVendor((prev) => {
      return { ...prev, [e.target.name]: phone };
    });
  };

  return (
    <div>
      {showConfetti && <Confetti className="confetti-success" />}
      <div className="vendor-modal-container customer-form">
        {finished === false && (
          <div>
            <h1 className="register-modal-title ">Create Account</h1>
            <p className="form-title small">Sign up and receive discounts on bottle service.</p>
            <div id="UserError"></div>
            <div className="vendor-modal-input-container">
              <label className="vendor-modal-label" htmlFor="full_name">
                <span className="req-asterisk">*</span>Full Name
              </label>
              <br />
              <input onChange={check_name} value={state.full_name} type="text" name="full_name" className="vendor-input" placeholder="e.g. John Doe" maxLength="75" required />
              <p id="errorName" className="error-msg"></p>
              <br />
              <label className="vendor-modal-label" htmlFor="phone_number">
                <span className="req-asterisk">*</span>Mobile Number
              </label>
              <br />
              <input onChange={check_phone} value={state.phone_number} type="tel" name="phone_number" className="vendor-input" maxLength="10" placeholder="xxxxxxxxxx" required />
              <p id="errorPhone" className="error-msg"></p>

              <br />
              <label className="vendor-modal-label" htmlFor="vendor_email">
                <span className="req-asterisk">*</span>Email
              </label>
              <br />
              <input type="text" value={state.vendor_email} onChange={check_email} name="vendor_email" className="vendor-input" maxLength="75" placeholder="youremail@domain.com" required />
              <p id="errorEmail" className="error-msg"></p>
            </div>
            {/**vendor-modal-input-container */}
            <div className="vendor-modal-button-wrapper">
              <button disabled={disabled} onClick={register_button} className="register-now">
                Register&nbsp;
                <FaSignature />
              </button>
              <p className="v-modal-disclaimer">
                We <b>Never</b> use your information for sales, spam or marketing purposes. By clicking register you agree to our (Tipit) Terms & Conditions, Policy. The information you provide us
                with is securely stored and encrypted.
              </p>
            </div>
          </div>
        )}
        {finished === true && <FormComplete onFormSuccess={handleFormSuccess} />}
      </div>
    </div>
  );
};

export default Vendors;
