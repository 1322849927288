import React from "react";
import { Provider } from "./Components/Store/store";
import App from "./App";

const AppMain = () => {
  return (
    <>
      <Provider>
        <App />
      </Provider>
    </>
  );
};

export default AppMain;
