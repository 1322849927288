import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TipitLogo from "../../static/img/tipit_logo_pro(whitey).png";
import PropTypes from "prop-types";

const ModalSignUp = ({ children, toggle = false, handleClose }) => {
  const [show, setShow] = useState(toggle);

  return (
    <>
      <Modal show={show} onHide={handleClose} className="signup-modal">
        <Modal.Body className="signup-modal-body">
          <div className="signup-header-container">
            <div className="register-modal-logo-container">
              <img src={TipitLogo} alt="tipit-logo" className="register-modal-logo" />
            </div>
            <Button className="su-modal-close" variant="secondary" onClick={handleClose}>
              &times;
            </Button>
          </div>
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
};

ModalSignUp.propTypes = {
  children: PropTypes.node.isRequired,
  toggle: PropTypes.bool,
  handleClose: PropTypes.bool,
};

export default ModalSignUp;
